import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import ContextConsumer from "./context"

export default function About() {
  return (
    <div className="h-100">
      <div className="h-100 flex flex-column items-center justify-start">
        <div className="pa3 w-90 tc br3 mb3 about">
          <p>
            A podcast for people who want to learn UX writing, Content Marketing, Content Design and Copywriting. Join Nicole Michaelis and guests to discuss the practice (and art) of all things content.
          </p>
          <p className="mt3">
            Connect with Nicole at{" "}
            <a
              href="mailto:hi@nicoletells.com"
              rel="noreferrer"
              target="_blank"
            >
              hi@nicoletells.com
            </a>{" "}
            or{" "}
            <a
              href="https://www.nicoletells.com/"
              rel="noreferrer"
              target="_blank"
            >
              nicoletells.com
            </a>
            !
          </p>
        </div>
        <div className="w-90 tj br3 mb3 flex justify-around">
          <div>
            <a
              href="https://twitter.com/nicoletellsit"
              target="_blank"
              rel="noreferrer"
            >
              <StaticImage
                src="../images/twitter.png"
                alt="@nicoletellsit"
                placeholder="tracedSVG"
                layout="fixed"
                width={32}
                height={32}
              />
            </a>
          </div>
          <div
            style={{
              background: "linear-gradient(0deg, white, white) 2px 2px/28px 28px no-repeat",
            }}
          >
            <a
              href="https://www.linkedin.com/in/nicoletells/"
              target="_blank"
              rel="noreferrer"
            >
              <StaticImage
                src="../images/linkedin.png"
                alt="@nicolemichaelis"
                placeholder="tracedSVG"
                layout="fixed"
                width={32}
                height={32}
              />
            </a>
          </div>
        </div>
        <div className="w-90 player">
          <ContextConsumer>
            {({ data }) => (
              <iframe
                src={`https://open.spotify.com/embed-podcast/episode/${data.player}`}
                width="100%"
                height="232"
                frameBorder="0"
                allowtransparency="true"
                allow="encrypted-media"
                title="player"
              ></iframe>
            )}
          </ContextConsumer>
        </div>
      </div>
    </div>
  )
}
