import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Links = () => {
  return (
    <div className="links-container flex flex-wrap pa3 flex-column-reverse-ns items-center justify-around">
      <div style={{ minWidth: `32px` }}>
        <a
          href="https://podcasts.apple.com/us/podcast/content-rookie/id1504195578?uo=4"
          target="_blank"
          rel="noreferrer"
        >
          <StaticImage
            src="../images/apple_podcasts.png"
            alt="Apple Podcasts"
            placeholder="tracedSVG"
            layout="fixed"
            width={32}
            height={32}
          />
        </a>
      </div>
      <div style={{ minWidth: `32px` }}>
        <a
          href="https://www.breaker.audio/content-rookie"
          target="_blank"
          rel="noreferrer"
        >
          <StaticImage
            src="../images/breaker.png"
            alt="Breaker"
            placeholder="tracedSVG"
            layout="fixed"
            width={32}
            height={32}
          />
        </a>
      </div>
      <div style={{ minWidth: `32px` }}>
        <a
          href="https://www.google.com/podcasts?feed=aHR0cHM6Ly9hbmNob3IuZm0vcy8xNzI0OTE3NC9wb2RjYXN0L3Jzcw=="
          target="_blank"
          rel="noreferrer"
        >
          <StaticImage
            src="../images/google_podcasts.png"
            alt="Google Podcasts"
            placeholder="tracedSVG"
            layout="fixed"
            width={32}
            height={32}
          />
        </a>
      </div>
      <div style={{ minWidth: `32px` }}>
        <a
          href="https://overcast.fm/itunes1504195578/content-rookie"
          target="_blank"
          rel="noreferrer"
        >
          <StaticImage
            src="../images/overcast.png"
            alt="Overcast"
            placeholder="tracedSVG"
            layout="fixed"
            width={32}
            height={32}
          />
        </a>
      </div>
      <div style={{ minWidth: `32px` }}>
        <a href="https://pca.st/mydraqse" target="_blank" rel="noreferrer">
          <StaticImage
            src="../images/pocket_casts.png"
            alt="Pocket Casts"
            placeholder="tracedSVG"
            layout="fixed"
            width={32}
            height={32}
          />
        </a>
      </div>
      <div style={{ minWidth: `32px` }}>
        <a
          href="https://radiopublic.com/content-rookie-GZ2L53"
          target="_blank"
          rel="noreferrer"
        >
          <StaticImage
            src="../images/radiopublic.png"
            alt="RadioPublic"
            placeholder="tracedSVG"
            layout="fixed"
            width={32}
            height={32}
          />
        </a>
      </div>
      <div style={{ minWidth: `32px` }}>
        <a
          href="https://open.spotify.com/show/68tt1i43PKfXqJ3SVL6Gjf"
          target="_blank"
          rel="noreferrer"
        >
          <StaticImage
            src="../images/spotify.png"
            alt="Spotify"
            placeholder="tracedSVG"
            layout="fixed"
            width={32}
            height={32}
          />
        </a>
      </div>
    </div>
  )
}

export default Links
