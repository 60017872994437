import "tachyons"
import "./reset.css"
import "./layout.css"
import React, { useEffect, useState, useRef } from "react"
import About from "./about"
import Links from "./links"
import { StaticImage } from "gatsby-plugin-image"
import { ContextProviderComponent } from "./context"
import SEO from "../components/seo"
import SocialImage from "../images/icon.png"

const Layout = ({ children }) => {
  const title = "Content Rookie - A Podcast on all things Content!"
  const marqueeText = new Array(100).fill(title).join(" — ")

  // SCROLL STUFF
  const scrollRef = useRef(null)
  const mobileScrollRef = useRef(null)
  const [offset, setOffset] = useState(0)

  const isBrowser = typeof window !== "undefined"
  let screenSize = 1024
  if (isBrowser) {
    screenSize = window.screen.width
  }

  useEffect(() => {
    const myRef = screenSize < 480 ? mobileScrollRef.current : scrollRef.current

    function handleScroll() {
      const elHeight = myRef.scrollHeight - myRef.offsetHeight
      const pos = myRef.scrollTop
      const percentage = (pos / elHeight) * 1000
      document.body.style.backgroundPosition = `${percentage}% 0%`
      setOffset(percentage)
    }

    document.body.style.backgroundPosition = `${offset}% 0%`
    myRef.addEventListener("scroll", handleScroll)

    return () => {
      myRef.removeEventListener("scroll", handleScroll)
    }
  }, [offset, screenSize])

  return (
    <div className="full-height flex flex-column" ref={mobileScrollRef}>
      <SEO
        title="Content Rookie"
        description="A podcast for people who want to learn Content Marketing, Content Strategy and Copywriting. Join Nicole Michaelis and guests to discuss the practice (and art) of all things content."
        image={SocialImage}
      />
      <ContextProviderComponent>
        <div className="pa3 tl tl-ns pt1-ns pl3-ns h-title">
          <div className="h-100 flex items-start">
            <StaticImage
              src="../images/logo.jpg"
              alt="Content Rookie"
              placeholder="tracedSVG"
              layout="fixed"
              width={100}
              height={100}
              className="br4"
              imgClassName="logo-top"
            />
            <div className="pl1">
              <div className="marquee pt3">
                <span>{marqueeText}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-column flex-row-ns h-content justify-around items-stretch">
          <div className="w-100 w-20-ns order-1 left-column">
            <About />
          </div>
          <div
            className="w-100 w-70-ns overflow-y pl3-ns pr3-ns order-2"
            ref={scrollRef}
          >
            {children}
          </div>
          <div className="w-100 w-10-ns order-0 order-last-ns">
            <Links />
          </div>
        </div>
        <div className="h-player"></div>
      </ContextProviderComponent>
    </div>
  )
}

export default Layout
